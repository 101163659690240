import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import tableStyle, {
  tableColumnStyle,
  tableRowStyle,
} from "../../common/StyleBuilder/tableStyle";

const STYLE_TYPES = {
  row: tableRowStyle,
  col: tableColumnStyle,
};

const TablePopup = (props) => {
  const { element, onSave, onClose, customProps, styleType } = props;
  const styles = STYLE_TYPES[styleType] || tableStyle;

  return (
    <StyleBuilder
      title="Table"
      type="gridStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={styles}
      customProps={customProps}
    />
  );
};

export default TablePopup;
