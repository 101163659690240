import { Editor, Node, Path, Transforms } from "slate";

export const insertBrain = (editor, data, position) => {

    if (!editor || !data || !position) {
        console.error("Editor, data, and position are required.");
        return;
    }

    const { selection } = editor;
    let insertPath = position;

    if (selection) {
        const brains = {
            type: "brains",
            ...data,
            children: [{ text: "" }],
        };
        const [currentNode, currentPath] = Editor.node(editor, selection);
        const cleanedText = Node.string(currentNode)
        .replace(/\/b(?:rain|r|ra|rai)?/g, "")
        .trim();
        
        if (cleanedText) {
            Transforms.delete(editor, { at: Editor.range(editor, currentPath) });
            Transforms.insertText(editor, cleanedText, { at: currentPath });
            const [, parentPath] = Editor.parent(editor, currentPath);
            insertPath = Path.next(parentPath);
        }

        Transforms.insertNodes(editor, brains, { at: insertPath, select: true });
        Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] });
    }
};

