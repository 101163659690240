const TableStyles = (theme) => ({
  tableToolBar: {
    position: "absolute",
    top: "-24px",
    left: 0,
    transition: "all 0.3s",

    "& button": {
      backgroundColor: theme?.palette?.editor?.background,
      border: "1px solid rgba(37, 99, 235, 0.32)",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
      borderRadius: "50%",
      padding: "4px",
      margin: "2px",

      "&:hover": {
        backgroundColor: theme?.palette?.editor?.background,
      },

      "& svg": {
        width: "16px",
        height: "16px",

        "& path": {
          stroke: theme?.palette?.editor?.textColor,
        },
      },
    },
    "&.active": {
      width: "auto",
      "& .toggle": {
        rotate: "180deg",
      },
    },
    "& .toolbtn": {
      "& svg": {
        stroke: "rgb(100, 116, 139)",
      },
    },
  },
  table: {
    "& td": {
      padding: "8px 8px",
    },
    "& .selection-area-tc": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      outline: "1px solid #2563EB",
      pointerEvents: "none",
    },
    "& .hide-drag-btn-wrapper": {
      "& .row-drag-btn": {
        display: "none",
      },
    },
  },
  cellResizer: {
    position: "absolute",
    cursor: "col-resize",
    right: "-2px",
    top: 0,
    background: "transparent",
    width: "3px",
    borderRadius: "0px",
    zIndex: 1,
    "&:hover": {
      background: "#2563EB",
    },
  },
});

export default TableStyles;
