import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import ImageSelector from "../../ImageSelector/ImageSelector";
import UploadStyles from "../../ImageSelector/UploadStyles";
import Icon from "../../Icon";
import { useEditorContext } from "../../../hooks/useMouseMove";

const BackgroundImage = (props) => {
  const { value, data, customProps, onChange } = props;
  const { key } = data;
  const [base64, setBase64] = useState(value);
  const [open, setOpen] = useState(false);
  const { theme } = useEditorContext();
  const classes = UploadStyles(theme);

  const onRemoveBG = () => {
    setBase64(null);
    onChange({
      [key]: "none",
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectImage = (img) => {
    setBase64(img);
    onChange({
      [key]: img,
    });
    handleClose();
  };

  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "8px" }}
      >
        Upload Image
      </Typography>
      <Grid container sx={classes.uploadContainer}>
        <Grid
          item
          xs={12}
          style={{
            backgroundImage: base64 ? `url(${base64})` : "none",
            height: "200px",
          }}
          sx={classes.uploadField}
        >
          <Grid className="uploadImageSection">
            {base64 ? (
              <Grid className="removeImageText" onClick={onRemoveBG}>
                REMOVE
              </Grid>
            ) : (
                <Grid className="uploadImageText" sx={{ padding: 0, background: `${theme?.palette?.editor?.inputFieldBgColor}`, border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}` }}>
                <Button
                  component="label"
                  variant="text"
                  style={{ background: "none" }}
                  onClick={handleClick}
                  sx={classes.uploadIcon}
                >
                  <Icon icon={"fileUpload"} />
                  <span style={{ paddingLeft: "8px" }}>upload your image</span>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ImageSelector
        open={open}
        onClose={handleClose}
        customProps={customProps}
        onSelectImage={onSelectImage}
      />
    </Grid>
  );
};

export default BackgroundImage;
