import React, { useEffect, useRef, useState } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import {
  Box,
  IconButton,
  Tooltip,
  Table as TableComp,
  TableBody,
  useTheme,
  Popper,
  Fade,
} from "@mui/material";
import { TableUtil } from "../../utils/table";
import TablePopup from "./TablePopup";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import TableStyles from "./Styles";
import "./table.css";
import { groupByBreakpoint } from "../../helper/theme";
import useTable, { TableProvider } from "../../hooks/useTable";
import AddRowCol from "./AddRowCol";
import TableTool from "./TableTool";
import { useDebouncedCallback } from "use-debounce";
import { MoreIcon, SettingsIcon } from "../../assets/svg/TableIcons";

const hideRowDragBtns = (hide, dragRowBtnCls) => {
  const rowDragBtns = document.querySelectorAll(`.${dragRowBtnCls}`);

  if (rowDragBtns?.length) {
    rowDragBtns?.forEach((btn) => (btn.style.display = hide));
  }
};

const ToolBar = (props) => {
  const {
    selected,
    showTool,
    classes,
    handleExpand,
    handleAction,
    exandTools,
  } = props;

  const { getSelectedCells } = useTable();

  const viewTool = selected && !showTool && getSelectedCells()?.length <= 1;

  return viewTool ? (
    <Box
      component={"div"}
      contentEditable={false}
      className={`tableToolBar ${exandTools ? "active" : ""}`}
      sx={classes.tableToolBar}
    >
      <Tooltip
        title={"Settings"}
        arrow
        onClick={() => handleAction("settings")}
      >
        <IconButton className="toolbtn toggle">
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Show Tools"} arrow onClick={handleExpand}>
        <IconButton className="toolbtn toggle">
          <MoreIcon />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};

const Table = (props) => {
  const theme = useTheme();
  const { theme: editorTheme } = useEditorContext();
  const { element, attributes, children, customProps } = props;
  const classes = TableStyles(editorTheme);
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const [exandTools, setExpandTools] = useState(null);
  const {
    bgColor,
    borderColor,
    xsHidden,
    fontFamily,
    fontWeight,
    textSize,
    textColor,
  } = element;
  const editor = useSlateStatic();
  const selected = useSelected();
  const table = new TableUtil(editor);
  const tableProps = table.getTableProps();
  const [showTool] = useEditorSelection(editor);
  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const path = ReactEditor.findPath(editor, element);
  const dragRowBtnCls = `table-${path
    ?.toString()
    ?.replaceAll(",", "-")}-row-drag-btn`;

  const handleAction = (type) => {
    Transforms.select(editor, editor.selection);
    switch (type) {
      case "delete":
        table.removeTable();
        break;
      case "duplicate":
        table.duplicateTable();
        break;
      case "settings":
        if (tableProps) {
          onSettings(true);
        }
        break;
      default:
        return;
    }
  };

  const handleExpand = (e) => {
    setExpandTools(e.currentTarget);
  };

  useEffect(() => {
    if (!selected) {
      setExpandTools(false);
    }
  }, [selected]);

  const onSettings = () => {
    setOpenSettings(!openSetttings);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    delete updateData.type;
    table.updateTableStyle(updateData, {
      ...tableProps,
    });
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const tableSX = groupByBreakpoint(
    {
      display: {
        xs: xsHidden ? "none" : "inline-block",
        lg: "inline-block",
      },
    },
    theme
  );

  const addRow = () => {
    const lastRow = element?.rows - 1;
    const firstCol = 0;

    const lastRowPath = [...path, lastRow, firstCol];

    const position = Editor.start(editor, lastRowPath);

    const selection = {
      anchor: position,
      focus: position,
    };

    // select the last row first col to insert row below
    Transforms.select(editor, selection);

    table.insertRow("after");

    Transforms.deselect(editor);
  };

  const addCol = () => {
    const lastCol = element?.columns - 1;
    const firstRow = 0;

    const lastColumnPath = [...path, firstRow, lastCol];
    const position = Editor.start(editor, lastColumnPath);

    const selection = {
      anchor: position,
      focus: position,
    };

    // select the last row first col to insert row below
    Transforms.select(editor, selection);

    table.insertColumn("after");

    Transforms.deselect(editor);
  };

  const handleRowDragBtns = () => {
    if (containerRef?.current?.scrollLeft > 0) {
      hideRowDragBtns("none", dragRowBtnCls);
    } else {
      hideRowDragBtns("", dragRowBtnCls);
    }
  };

  const handleScrollStop = useDebouncedCallback(() => {
    containerRef?.current?.classList.add("hideScroll");
  }, 200);

  const handleScroll = () => {
    handleRowDragBtns();

    containerRef?.current?.classList.remove("hideScroll");
    handleScrollStop();
  };

  const commonAddBtnProps = {
    tableRef,
    containerRef,
    readOnly,
    tableNode: element,
  };

  return (
    <TableProvider
      editor={editor}
      otherProps={{ dragRowBtnCls, tablePath: path, openSetttings }}
    >
      <div
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          position: "relative",
          overflowX: "auto",
          display: "flex",
          paddingTop: "10px",
        }}
        ref={containerRef}
        onScroll={handleScroll}
        className="hideScroll"
      >
        <TableComp
          className={readOnly ? "readOnly" : ""}
          sx={{
            ...classes.table,
            ...tableSX,
          }}
          style={{
            background: bgColor,
            border: borderColor ? `1px solid ${borderColor}` : "",
            width: "auto",
            fontFamily,
            fontWeight,
            fontSize: textSize,
            color: textColor,
          }}
          ref={tableRef}
        >
          <TableBody {...attributes}>{children}</TableBody>
        </TableComp>

        <AddRowCol {...commonAddBtnProps} addType="col" onAdd={addCol} />
      </div>

      <AddRowCol {...commonAddBtnProps} addType="row" onAdd={addRow} />

      {!readOnly && (
        <ToolBar
          selected={selected}
          showTool={showTool}
          classes={classes}
          handleExpand={handleExpand}
          handleAction={handleAction}
          exandTools={exandTools}
        />
      )}

      <Popper
        open={Boolean(exandTools)}
        anchorEl={exandTools}
        transition
        contentEditable={false}
        sx={{ zIndex: 2000 }}
        placement="top"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <TableTool theme={editorTheme} handleToolAction={handleAction} />
            </div>
          </Fade>
        )}
      </Popper>

      {openSetttings ? (
        <TablePopup
          element={tableProps?.styleProps || {}}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </TableProvider>
  );
};

export default Table;
