import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import { formStyle } from "../../common/StyleBuilder/formStyle";

const FormPopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <>
      <StyleBuilder
        title="Form"
        type="formStyle"
        element={element}
        onSave={onSave}
        onClose={onClose}
        renderTabs={formStyle}
        customProps={customProps}
      />
    </>
  );
};

export default FormPopup;
