import { Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from "../../common/Icon";
import { SearchIcon } from "../../common/iconslist";

const SearchAndDocList = ({ mapData, isLoading, lastDocRef, onSearchChange, handleClick, theme, handleClose }) => {

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '9px 16px 8px 16px' }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: theme?.palette?.primary?.main }}>
                    Brains
                </Typography>
                <IconButton
                    onClick={(e) => { e.stopPropagation(); handleClose() }}
                    sx={{
                        borderRadius: "6px",
                        backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
                        border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                        padding: "5px",
                        "&:hover": { backgroundColor: theme?.palette?.editor?.sectionSettingIconHover, background: theme?.palette?.editor?.sectionSettingIconHover },
                    }}
                >
                    <Icon icon='closeIcon' />
                </IconButton>
            </Box>
            <Box sx={{ padding: '0px 16px' }}>
                <Divider sx={{ borderColor: theme?.palette?.primary?.slashBrainBorder }} />
            </Box>
            <Box sx={{ padding: '12px 16px' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search here..."
                    size="small"
                    onChange={(e) => onSearchChange(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                            color: theme?.palette?.primary?.main,
                            boxShadow: theme?.palette?.shadows?.shadow12,
                            backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "8px",
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderColor: theme?.palette?.primary?.slashBrainBorder,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "8px",
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderColor: `${theme?.palette?.primary?.slashBrainBorder} !important`,
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: "8px",
                            border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                            borderColor: theme?.palette?.primary?.slashBrainBorder,
                        },
                        '& .MuiInputBase-root': {
                            borderRadius: '8px'
                        },
                        "& svg": {
                            "& path": { stroke: theme?.palette?.text?.text6 },
                        },

                    }}
                />
            </Box>
            <Grid
                container
                gap={1}
                sx={{
                    position: 'relative',
                    maxHeight: '400px',
                    minWidth: "275px",
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0px 16px 8px',
                    marginBottom: '20px',
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme?.palette?.primary?.slashBrainBorder} transparent`,
                    "&::-webkit-scrollbar": {
                        width: "3px",
                        height: "3px !important",
                        borderRadius: "10px !important",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme?.palette?.primary?.slashBrainBorder,
                        borderRadius: "10px !important",
                        width: "3px !important",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: theme?.palette?.primary?.slashBrainBorder,
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent",
                        borderRadius: "10px !important",
                    },
                }}
            >

                {mapData?.map((doc, index) => {
                    const title = doc?.title?.trim() === "" ? 'Untitled' : doc?.title;
                    return (
                        <Grid
                            item
                            xs={12}
                            key={doc.id}
                            ref={index === mapData.length - 1 ? lastDocRef : null}
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderRadius: '8px',
                                padding: '6px 10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleClick(doc)}
                        >
                            <Grid container alignItems="center" justifyContent={'space-between'} gap={1}>
                                <Grid item>
                                    <Grid container alignItems="center" gap={1}>
                                        <Grid item sx={{ marginTop: '2px', '& svg': { '& path': { stroke: theme?.palette?.primary?.slashBrainIcon } } }}>
                                            <Icon icon="docsIcon" />
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{
                                                fontSize: "12px", fontWeight: 500,
                                                color: theme?.palette?.primary?.main,
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                wordBreak: "break-word",
                                                maxWidth: '230px',
                                                textAlign: 'left'
                                            }}>
                                                {title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    doc?.shared_status === 1 ?
                                        <Grid item sx={{ '& svg': { '& path': { stroke: theme?.palette?.primary?.slashBrainIcon } } }}>
                                            <Icon icon="userIcon" />
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    )
                })}
                { isLoading &&
                    <Box display="flex" justifyContent="center" alignItems={'center'} sx={{ minHeight: '400px', padding: '0px 16px 8px', position: 'absolute' }}>
                        <CircularProgress />
                    </Box>
                }
            </Grid>
        </>
    )
}

export default SearchAndDocList