const useCommonStyle = (theme) => ({
  sideBarDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiInputBase-input": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiCheckbox-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& .close-popupbtn": {
      color: theme?.palette?.editor?.textColor,
    },
  },
  sapcingInput: {
    backgroundColor: theme?.palette?.editor?.background,
    color: theme?.palette?.editor?.textColor,
  },
  colorPickerPopup: {
    "& .MuiPaper-root": {
      overflow: "auto",
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs-header": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .popup_tabs-header-label-active": {
        color: theme?.palette?.editor?.activeColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
    "& .colorpicker": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .default-color-panel": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 20px)",
        gridGap: "3px",
        justifyContent: "space-between",
        margin: "14px -16px 0px -16px",
        padding: "2px 16px 0px 16px",
        overflow: "auto",
        maxHeight: "105px",
        "& .default-color-panel_item": {
          height: "20px",
          width: "20px",
          border: "1px solid #E2E8F0",
        },
      },
    },
    "& .color-picker-panel": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .input_rgba": {
      "& input": {
        backgroundColor: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  signaturePopup: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
      "& .signature-canvas": {
        backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
      },
      "& label": {
        color: theme?.palette?.editor?.textColor,
      },
      "& input": {
        color: theme?.palette?.editor?.textColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme?.palette?.editor?.background,
      },
      "& .brush-color": {
        color: theme?.palette?.editor?.textColor,
      },
      "& .react-datepicker__input-container": {
        "& svg": {
          fill: "transparent",
        },

        "& input": {
          height: "27px !important",
        },
      },
      "& fieldset": {
        borderColor: theme?.palette?.editor?.textColor,
      },
      "& .upload-wrapper-ui": {
        backgroundColor:
          theme?.palette?.type === "dark" ? "#2563EB0F" : "#F8F9FF",

        "& .MuiTypography-root": {
          color: theme?.palette?.editor?.textColor,
        },
      },
      "& .typesignature-input-wrapper": {
        "& .MuiInputBase-root": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
          borderRadius: "7px !important",
          paddingRight: "6px !important",
        },
        "& .MuiInputBase-input": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder}`,
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid #2563EB !important`,
          },
        },
        "& button": {
          background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
          color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        },
      },
      "& .typesignature-fontfamily": {
        "& button": {
          color: theme?.palette?.editor?.textColor,
          background: `${theme?.palette?.editor?.signatureFontBtnBg} !important`,
          border: `1px solid ${theme?.palette?.editor?.sectionSettingIconHover} !important`,
          "& p": {
            fontSize: "13px !important",
          },
        },
        "& .active ": {
          border: `1px solid #2563EB !important`,
        },
      },
      "& .MuiTab-root": {
        color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        opacity: "unset",
        fontSize: "12px !important",
        fontWeight: "500 !important",
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpTabButtonBorder} !important`,
      },
      "& .Mui-selected": {
        background: `${theme?.palette?.editor?.signaturePopUpTabButtonSelectedBg} !important`,
        color: `${theme?.palette?.editor?.textColor} !important`,
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpTabButtonSelectedBorder} !important`,
        "& .MuiGrid-root": {
          "& svg": {
            "& path": {
              fill: `${theme?.palette?.editor?.signaturePopUpTabButtonSelectedSvg} !important`,
            },
          },
        },
      },
      "& .close-popupbtn": {
        color: theme?.palette?.editor?.closeButtonSvgStroke,
        background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
      },
      "& .deleteBtn": {
        background: "transparent",
        margin: '0px !important'
      },
      "& .MuiGrid-container": {
        "& .workFlowButton": {
          background: `${theme?.palette?.editor?.workFlowButtonBackground} !important`,
          textTransform: "unset !important",
          color: `${theme?.palette?.editor?.textColor} !important`,
          fontSize: "14px !important",
          fontWeight: "500 !important",
          borderRadius: "25px",
          padding: "5px 15px",
        },
      },
      "& .MuiDialogActions-root": {
        "& .deleteButtonSignature": {
          background: `${theme?.palette?.editor?.closeButtonBackground} !important`,
          color: `${theme?.palette?.editor?.deletePopUpButtonTextColor} !important`,
          border: `1px solid ${theme?.palette?.editor?.closeButtonBorder} !important`,
        },
      },
    },
    "& .disabledSaveBtn": {
      background: "#2563EB33 !important",
      color: theme?.palette?.type === "dark" && "#FFFFFF33 !important",
      border: "1px solid #2563EB33 !important",
    },
  },
  popupTitle2: {
    fontWeight: 600,
    color: theme?.palette?.editor?.textColor,
  },
  textOptions: {
    "& .MuiPopover-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
  },
  datePicker: {
    "& .react-datepicker-wrapper": {
      width: "100%",
      "& .react-datepicker__input-container": {
        "& .MuiInputBase-root": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
          "& .MuiInputBase-input": {
            background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
            color: `${theme?.palette?.editor?.textColor} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder} !important`,
          },
        },
      },
    },
  },
  signaturePopUpNameField: {
    background: "transparent !important",
    "& .MuiInputBase-root": {
      borderRadius: "10px !important",
      background: "transparent !important",
      "& .MuiOutlinedInput-input": {
        background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        borderRadius: "10px !important",
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder} !important`,
        fontSize: "14px !important",
        fontWeight: "500 !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid transparent !important`,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-input": {
        border: `1px solid #2563EB !important`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid transparent !important`,
      },
    },
  },
});

export default useCommonStyle;
