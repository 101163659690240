import React from "react";
import { Transforms, Node } from "slate";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import { formStyle } from "../../../StyleBuilder/formStyle";
import { ReactEditor } from "slate-react";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useEditorContext } from "../../../../hooks/useMouseMove";

const FormSettings = (props) => {
  const { editor, path, customProps } = props;
  const item_path = path?.split("|").map((m) => parseInt(m));
  const element_path = [...item_path];
  const element = Node.get(editor, element_path);
  const styleMaps = formStyle;
  const { hideTools } = customProps || {};
  const { theme } = useEditorContext();

  const onChange = (data) => {
    const currentPath = ReactEditor.findPath(editor, element);
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...currentPath, 0],
      }
    );
    // adding form field style to the current form node
    const currentNode = Node.get(editor, element_path);
    if (currentNode) {
      currentNode?.children[0]?.children?.forEach((item, index) => {
        Transforms.setNodes(
          editor,
          {
            bgColor: data?.fieldBgColor ? data?.fieldBgColor : item?.bgColor,
            borderColor: data?.fieldBorderColor
              ? data?.fieldBorderColor
              : item?.borderColor,
            borderStyle: data?.fieldBorderStyle
              ? data?.fieldBorderStyle
              : item?.borderStyle,
            borderWidth: data?.fieldBorderWidth
              ? data?.fieldBorderWidth
              : item?.borderWidth,
            textColor: data?.fieldTextColor
              ? data?.fieldTextColor
              : item?.textColor,
            fontFamily: data?.fieldFontFamily
              ? data?.fieldFontFamily
              : item?.fontFamily,
            textSize: data?.fieldTextSize
              ? data?.fieldTextSize
              : item?.textSize,
            fontWeight: data?.fieldFontWeight
              ? data?.fieldFontWeight
              : item?.fontWeight,
          },
          { at: [...currentPath, 0, index] }
        );
      });
    }
  };

  const handleClose = () => {
    console.log("close");
  };

  const muiTheme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: theme?.palette?.editor?.miniToolBarBackground,
            "& .MuiAccordionSummary-root": {
              flexDirection: "row-reverse",
              "& .MuiSvgIcon-root": {
                color: theme?.palette?.primary?.main,
              },
              "& .Mui-expanded": {
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.text?.blueText,
                },
              },
            },
            padding: "0px",
            "&.Mui-expanded": {
              margin: "0",
            },
          },
        },
        defaultProps: {
          elevation: 0,
        },
      },
    },
  });

  return (
    <Box component={"div"} className="item-w">
      {styleMaps?.map((m, i) => {
        if (m?.hasChildrenTabs) {
          return (
            <ThemeProvider theme={muiTheme} key={i}>
              <Accordion key={`accordion_${i}`} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  sx={{ padding: "0px" }}
                >
                  <Typography
                    variant="body1"
                    className="settingsHeader"
                    color={"primary"}
                    style={{
                      fontSize: "14px !important",
                      fontWeight: "400",
                    }}
                  >
                    {m?.tab}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "5px" }}>
                  {m?.fields
                    .filter((f) => (hideTools || []).indexOf(f.value) === -1)
                    .map((field, index) => {
                      return (
                        <StyleContent
                          key={`tab_${field.value}_${index}`}
                          renderTabs={[field]}
                          value={field.value}
                          element={element}
                          onChange={onChange}
                          customElement={element?.children?.[0] || null}
                          customProps={customProps}
                          handleClose={handleClose}
                        />
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          );
        } else {
          return (
            <StyleContent
              key={`tab_${m.value}_$${i}`}
              renderTabs={styleMaps}
              value={m.value}
              element={element}
              customElement={element?.children?.[0] || null}
              onChange={onChange}
              customProps={customProps}
              handleClose={handleClose}
            />
          );
        }
      })}
    </Box>
  );
};

export default FormSettings;
