import React, { createContext, useContext, useState } from "react";
import { Path, Node, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";

const GridContext = createContext();

const getAdjacentColumnPath = (editor, currentPath, direction = "next") => {
  const parentPath = Path.parent(currentPath); // Get the parent grid path
  const gridNode = Node.get(editor, parentPath); // Get the grid node

  const currentIndex = currentPath[currentPath.length - 1]; // Index of the current column
  const isNext = direction === "next";

  const adjacentIndex = isNext ? currentIndex + 1 : currentIndex - 1;

  // Ensure the adjacent index is valid
  if (adjacentIndex < 0 || adjacentIndex >= gridNode.children.length) {
    return {
      parentPath,
    }; // No adjacent column exists in the given direction
  }

  return {
    parentPath: [...parentPath],
    rightColPath: [...parentPath, adjacentIndex],
  }; // Return the adjacent column's path
};

const isSingleColumn = (editor, currentPath) => {
  try {
    const parentPath = Path.parent(currentPath); // Get the parent grid path
    const gridNode = Node.get(editor, parentPath);
    return gridNode?.children?.length === 1;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getChildCount = (editor, currentPath) => {
  try {
    const parentPath = Path.parent(currentPath); // Get the parent grid path
    const gridNode = Node.get(editor, parentPath);
    return gridNode?.children?.length;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const GridProvider = ({ children }) => {
  const editor = useSlateStatic();
  const [columnWidths, setColumnWidths] = useState({});
  const [widths, setWidths] = useState({});

  const initColumnWidth = (currentColPath = [], currentMinWidth = 0) => {
    try {
      let right = 0;
      const { parentPath, rightColPath } = getAdjacentColumnPath(
        editor,
        currentColPath
      );
      if (rightColPath) {
        const parentPath = Path.parent(rightColPath); // Get the parent grid path
        const gridNode = Node.get(editor, parentPath);
        const gridNodeDom = ReactEditor.toDOMNode(editor, gridNode);
        const rightColNode = Node.get(editor, rightColPath);
        const rightColDom = ReactEditor.toDOMNode(editor, rightColNode);
        right =
          rightColNode?.minWidth ||
          (rightColDom?.offsetWidth / gridNodeDom?.offsetWidth) * 100;
      }
      setColumnWidths({
        left: currentMinWidth,
        right,
        rightPath: rightColPath?.join(),
        rightIndex: rightColPath,
        parentPath: parentPath,
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @param {*} data - contains {left: calculatedWidth}
   */
  const updateColumnWidth = (data, currentColPath) => {
    // logic to update right column width
    const right = columnWidths?.right - (data?.left - columnWidths?.left);
    const diff = right !== widths?.right;
    if (isSingleColumn(editor, currentColPath) && data?.left <= 100) {
      setWidths({
        ...data,
        right: right,
      });
    } else if (right && right > 10 && data?.left > 10 && diff) {
      setWidths({
        ...data,
        right: right,
      });
    }
  };

  const updateColNodes = (currentColPath = []) => {
    try {
      if (widths?.left) {
        Transforms.setNodes(
          editor,
          {
            minWidth: widths?.left,
          },
          { at: currentColPath }
        );
        // update right column node
        if (columnWidths?.rightPath) {
          Transforms.setNodes(
            editor,
            {
              minWidth: widths?.right,
            },
            { at: columnWidths?.rightIndex }
          );
        }
      }
      setColumnWidths({});
      setWidths({});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GridContext.Provider
      value={{
        widths,
        updateColumnWidth,
        initColumnWidth,
        columnWidths,
        updateColNodes,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

export const useGrid = () => useContext(GridContext);
