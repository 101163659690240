const usePopupStyles = (theme) => ({
  root: {
    position: "absolute",
    zIndex: 1300,
    borderRadius: "10px",
    padding: "0px",
    boxShadow: "0px 4px 10px 0px #00000029",
    overflow: "hidden",
    padding: "8px 0px !important",
    background: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
    "& .MuiPaper-root": {
      background: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      "& .MuiBox-root": {
        "& .renderComp": {
          padding: "2px 0px 2px 1px",
          background: "transparent !important",
        },
        "& button": {
          padding: "0px 0px 0px 6px",
          "& svg": {
            width: "19px !important",
            paddingRight: "6px",
          },
        },
        "& .active": {
          border: "unset !important",
          "& .MuiPaper-root": {
            background: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`,
            borderRadius: "7px",
            "& .MuiBox-root": {
              "& .MuiCardContent-root": {
                "& .MuiTypography-root": {
                  color: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                },
              },
            },
          },
          "& button": {
            background: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`,
            borderRadius: "7px",
            "& svg": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
              },
            },
            "& span": {
              "& .MuiTypography-root": {
                color: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .BrainIcon": {
              "& .bainIconPathStroke": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                stroke: "unset !important",
              },
              "& .bainIconPathStroke2": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
          },
          "& .MuiCardMedia-root": {
            "& .signatureElementIcon": {
              "& path": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .commonSvgStyle": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .calederIconSvg": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
              "& rect": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .colorBoxElementIcon": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                fill: "none",
              },
            },
            "& .gridElementIcon": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .newLineElementIcon": {
              "& path": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .blockQuoteSvg": {
              fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
            },
            "& .orderedListIcon": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
              "& text": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .bulletedListTextIcon": {
              "& path": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
              "& circle": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .checkedListTextIcon": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .accordianListTextIcon": {
              // stroke: `${theme?.palette?.editor?.svgTextAlignStrokeColor} !important`,
              "& svg": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
              "& path": {
                fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
            "& .accordianIconSvgTextFormat": {
              "& path": {
                stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
              },
            },
          },
        },
        "&:hover": {
          "& .MuiPaper-root": {
            background: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`,
            borderRadius: "7px",
          },
        },
      },
    },
  },
  papper: {
    boxShadow: "none",
    width: "300px",
    maxHeight: "300px",
    overflow: "auto",
    padding: "8px",
    margin: "0px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.editor?.background,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& button": {
      color: theme?.palette?.editor?.textColor,
    },
  },
  groupHeader: {
    padding: "10px 8px",
    marginBottom: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme?.palette?.editor?.deviderBgColor}`,
  },
  listItem: {
    cursor: "pointer",
    background: "transparent",
    borderRadius: "10px",
    border: `1px solid transparent`,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "&.active": {
      background: `#2563EB2B`,

      "& .MuiTypography-root": {
        color: `${theme?.palette?.editor?.activeColor} !important`,
      },
    },
    "&:hover": {
      background: theme?.palette?.action?.hover || `#F4F4F4`,
    },
    "&.renderComp": {
      padding: "0px",
      "& button.cmd": {
        // to invalidate click event of toolbar icon
        // will trigger by forard ref
        pointerEvents: "none",
        width: "100%",
        padding: "4px",
        borderRadius: "0px",
        justifyContent: "start",
        "& svg": {
          margin: "5.5px",
          width: "24px",
          height: "24px",
        },
        span: {
          padding: "8px",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  cardMedia: {
    width: 24,
    height: 24,
    margin: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default usePopupStyles;
