import { getNode } from "../../utils/helper";
import { serializeToText } from "../../utils/serializeToText";

export const getRectangleBounds = (tableSelection) => {
  const { startCellPath, endCellPath } = tableSelection;

  if (!startCellPath?.length) return [];

  const startPath = startCellPath.slice(0, -2);
  const startCell = startCellPath.slice(-2);
  const endCell = endCellPath.slice(-2);

  const [startRow, startCol] = startCell;
  const [endRow, endCol] = endCell?.length ? endCell : startCell;

  const minRow = Math.min(startRow, endRow);
  const maxRow = Math.max(startRow, endRow);
  const minCol = Math.min(startCol, endCol);
  const maxCol = Math.max(startCol, endCol);

  const selectedPaths = [];
  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      selectedPaths.push([...startPath, row, col]);
    }
  }

  return selectedPaths;
};

export const createCopiedTableStructure = (
  editor,
  tableSelection,
  currentTableNode,
  tablePath,
  selectedText
) => {
  const selectedCells = getRectangleBounds(tableSelection);
  const formattedCells = selectedCells.map((cell) => cell.slice(-2));

  const tableChild = [];
  const rowIndexes = [...new Set(formattedCells.map((cell) => cell[0]))];

  rowIndexes.forEach((rowIndex, row_i) => {
    const row = {
      type: "table-row",
      children: [],
    };

    // Filter cells that belong to the current row
    const cellsInRow = formattedCells.filter((cell) => cell[0] === rowIndex);

    // Iterate over the columns of the row to create table cells
    const columnIndexes = [...new Set(cellsInRow.map((cell) => cell[1]))];

    columnIndexes.forEach((columnIndex, col_i) => {
      const cellPath = [...tablePath, rowIndex, columnIndex];
      const columnNode = getNode(editor, cellPath);

      const cellNode = selectedText // we can select text in only one column
        ? {
            ...columnNode,
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [{ text: selectedText }],
                cellBgColor: "#FFFFFF",
              },
            ],
          }
        : columnNode;

      row.children.push(cellNode);
    });

    tableChild.push(row);
  });

  const table = {
    ...currentTableNode,
    children: tableChild,
    rows: rowIndexes?.length,
    columns: tableChild[0]?.children?.length, // first row's children length
  };

  return table;
};

export const tableNodeToDom = (tableNode, selectedText) => {
  const tableEle = document.createElement("table");

  tableNode.children?.forEach((row) => {
    const rowEle = document.createElement("tr");

    row?.children?.forEach((cell) => {
      const cellEle = document.createElement("td");

      const cellText = selectedText || serializeToText(cell);

      cellEle.innerHTML = cellText;
      rowEle.appendChild(cellEle);
    });

    tableEle.appendChild(rowEle);
  });

  return tableEle;
};
