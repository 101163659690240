import React, { useState, useEffect, useMemo } from "react";
import { Editor, Element, Path, Transforms } from "slate";
import { Box } from "@mui/material";
import { useSlateStatic, ReactEditor } from "slate-react";
import useTableResize from "../../utils/customHooks/useTableResize";
import { TableUtil } from "../../utils/table";
import TableStyles from "./Styles";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import useTable, { getDefaultTableSelection } from "../../hooks/useTable";
import DragButton from "./DragButton";
import TablePopup from "./TablePopup";
import { Droppable } from "./Droppable";
import { useDndContext } from "@dnd-kit/core";

const Resizer = ({ classes, onMouseDown, height }) => {
  return (
    <>
      <Box
        component={"div"}
        className="cell-resizer"
        contentEditable={false}
        onPointerDown={onMouseDown}
        sx={classes.cellResizer}
        style={{ height: `${height}px` }}
      />
    </>
  );
};

const isCellEditable = (startCellPath, path) => {
  return (
    startCellPath?.length && startCellPath?.toString() === path?.toString()
  );
};

const TableCell = (props) => {
  const { theme } = useEditorContext();
  const classes = TableStyles(theme);
  const { element, attributes, children, customProps } = props;
  const { readOnly } = customProps;
  const {
    bgColor,
    borderColor,
    entireBgColor,
    entireBorderColor,
    entireTextColor,
    entireFontFamily,
    entireFontWeight,
    entireTextSize,
  } = element;

  const [parentDOM, setParentDOM] = useState(null);
  const editor = useSlateStatic();
  const [showTool] = useEditorSelection(editor);
  const {
    hoverPath,
    setHoverPath,
    getSelectedCells,
    updateTableSelection,
    tableSelection,
    tableResizing,
    setTableResizing,
    otherProps,
  } = useTable();
  const { active, over } = useDndContext();
  const currentDraggingType = active?.data?.current?.dragType;
  const { dragRowBtnCls } = otherProps || {};
  const { startCellPath } = tableSelection;
  const path = ReactEditor.findPath(editor, element);
  const isHeader = path.length >= 2 ? path[path.length - 2] === 0 : false;
  const [size, onMouseDown, resizing, onLoad] = useTableResize({
    parentDOM,
    size: element?.size,
  });
  const [tableSize, setTableSize] = useState({});
  const [openSettings, setOpenSettings] = useState(false);
  const table = new TableUtil(editor);
  const tableProps = table.getTableProps();
  const [tableNode] = Editor.nodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
  });
  const [rowNode] = Editor.nodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
  });
  const currentPath = path.slice(-2) || []; // getting last 2 items from path, which gives row and column position of the cell
  const [row, column] = currentPath;
  const isFirstRow = row === 0;
  const isFirstColumn = column === 0;
  const [hoverRow, hoverCol] = hoverPath ? hoverPath.slice(-2) : [];

  const showColDrag =
    isFirstRow && hoverCol === column && !resizing && !readOnly;
  const showRowDrag =
    isFirstColumn && hoverRow === row && !resizing && !readOnly;

  const [parentProps] = tableNode || [{}];
  const [rowProps] = rowNode || [{}];
  const tableDOM = table.getDOMNode(path, true);
  const isCellSelected = getSelectedCells();
  const hasSelected =
    (isCellSelected || [])?.findIndex((f) => f.join(",") === path.join(",")) >
    -1;

  const containerEle = tableDOM?.parentNode?.parentNode;

  useEffect(() => {
    if (tableDOM) {
      const { width, height } = tableDOM.getBoundingClientRect();
      const { width: parentWidth } =
        tableDOM?.parentNode?.parentNode?.getBoundingClientRect();
      const cellWidth = element?.size?.width;
      const columns = tableNode?.[0]?.columns;
      const oldTableWidth = parentWidth / columns - 4;

      setTableSize({
        width,
        height,
        parentWidth,
        cellWidth: cellWidth || oldTableWidth,
      });
    }
  }, [tableDOM]);

  useEffect(() => {
    if (editor && element && tableSize) {
      const dom = ReactEditor.toDOMNode(editor, element);
      setParentDOM(dom);

      const width = tableSize?.cellWidth;

      const size = element?.size
        ? {
            ...element?.size,
            width,
          }
        : {
            width,
            height: 100,
          };

      onLoad(size);
    }
  }, [tableSize]);

  const resetSelection = () => {
    Transforms.deselect(editor);
    updateTableSelection(getDefaultTableSelection());
  };

  useEffect(() => {
    const currentPath = path?.toString();

    setTimeout(() => {
      if (tableResizing === currentPath) {
        setTableResizing(null);

        // reset selection after resizing
        resetSelection();
      }
    }, 200);

    if (!resizing && tableResizing) {
      table.resizeTableCell({ "col.size": size }, path);
    }

    if (resizing) {
      setTableResizing(currentPath);
    }
  }, [resizing]);

  const onMouseEnter = (path) => {
    setHoverPath(path);

    if (tableSelection?.isDragging) {
      updateTableSelection({ endCellPath: path });

      const isSelectingOneCellToAnotherCell =
        startCellPath?.length && !Path.equals(path, startCellPath);

      if (isSelectingOneCellToAnotherCell) {
        Transforms.deselect(editor);
      }
    }
  };

  const onMouseLeave = () => {
    setHoverPath(null);
  };

  const onMouseDownInCell = (e) => {
    if (!contentEditable) {
      e.preventDefault();
    }

    if (
      // for shift selection
      e.shiftKey &&
      startCellPath?.length &&
      startCellPath.toString() !== path.toString()
    ) {
      updateTableSelection({
        endCellPath: path,
      });
    } else {
      updateTableSelection({
        startCellPath: path,
        endCellPath: [],
        isDragging: true,
      });
    }
  };

  const onMouseUp = () => {
    if (startCellPath?.length) {
      updateTableSelection({ endCellPath: path, isDragging: false });
    }
  };

  const onClick = () => {
    const currentEditorSelection = editor?.selection?.focus?.path || [];
    const selectionPath = currentEditorSelection?.slice(0, -2);
    const isCellSelected =
      selectionPath?.length && Path.equals(selectionPath, path);

    if (!isCellSelected) {
      // focus the clicked cell
      ReactEditor.focus(editor);
      Transforms.select(editor, {
        anchor: Editor.end(editor, path),
        focus: Editor.end(editor, path),
      });

      // after mousedown event, onclick is triggered, while onclick, dragging should be disabled and focus should be on that clicked cell to edit the contents inside it.
      updateTableSelection({
        isDragging: false,
      });
    }
  };

  const cellId = "table-cell" + path.toString();
  const cellRef = document.getElementById(cellId);

  const contentEditable = !readOnly && isCellEditable(startCellPath, path);

  const commonTdProps = useMemo(() => {
    const props = {
      id: cellId,
    };

    if (!contentEditable) {
      props.contentEditable = false;
    }

    return props;
  }, [contentEditable, cellId]);

  const handleTouchMove = (e) => {
    const touch = e.touches[0]; // Get the current touch point
    const element = document.elementFromPoint(touch.clientX, touch.clientY);

    if (element && element.dataset.cell) {
      const elementPath = element.dataset.cell.split(",").map(Number);
      onMouseEnter(elementPath);
    }
  };

  const handleTouchEnd = () => {
    updateTableSelection({
      isDragging: false,
    });
  };

  const tbProps =
    resizing || over || readOnly
      ? {
          ...commonTdProps,
          contentEditable: false,
        }
      : {
          ...commonTdProps,
          onMouseEnter: () => onMouseEnter(path),
          onMouseLeave,
          onMouseDown: onMouseDownInCell,
          onMouseUp,
          onClick,

          // mobile events for selection
          onTouchStart: onMouseDownInCell,
          onTouchMove: handleTouchMove,
          onTouchEnd: handleTouchEnd,
        };

  const dndProps = {
    id: cellId,
    data: {
      path,
    },
  };

  const isCellDragging = active?.id && active?.id === cellId;
  const isRowDragging = isCellDragging && currentDraggingType === "row";
  const isColDragging = isCellDragging && currentDraggingType === "col";

  const width = isHeader ? size?.width || tableSize?.cellWidth : "0px";
  const sizeProps = { minWidth: width, maxWidth: width };
  const cellBorderColor =
    borderColor ||
    rowProps?.borderColor ||
    parentProps?.borderColor ||
    entireBorderColor;

  const selectCurrentCell = () => {
    const cellPath = Editor.start(editor, path);

    const selection = {
      anchor: cellPath,
      focus: cellPath,
    };

    // select the cell to insert/delete/duplicate/clear
    Transforms.select(editor, selection);
  };

  const onRowDrag = () => {
    const { children } = rowNode[0] || {};
    const rowPath = rowNode[1] || [];
    const rowStartCell = [...rowPath, 0];
    const rowEndCell = [...rowPath, children?.length - 1];

    updateTableSelection({
      startCellPath: rowStartCell,
      endCellPath: rowEndCell,
      isDragging: false,
    });

    selectCurrentCell();
  };

  const onColDrag = () => {
    const [tableData, tablePath] = tableNode;

    const { rows } = tableData;

    const startColCell = [...tablePath, 0, column];
    const endColCell = [...tablePath, rows - 1, column];

    updateTableSelection({
      startCellPath: startColCell,
      endCellPath: endColCell,
      isDragging: false,
    });

    selectCurrentCell();
  };

  const handleToolAction = (value, option, dragType) => {
    const isRowDrag = dragType === "row";

    switch (value) {
      case "insertAbove":
        table.insertRow("at");
        break;
      case "insertBelow":
        table.insertRow("after");
        break;
      case "delete":
        const deleteFn = isRowDrag ? table.deleteRow : table.deleteColumn;
        deleteFn();

        setTimeout(() => resetSelection(), 200); // throws cannot find the descendant path error because of deletion
        break;
      case "duplicate":
        if (isRowDrag) {
          table.insertRow("duplicate");
        } else {
          table.duplicateColumn();
        }
        break;
      case "clear":
        if (isRowDrag) {
          table.clearRow();
        } else {
          table.clearColumn();
        }
        break;
      case "insertRight":
        table.insertColumn("after");
        break;
      case "insertLeft":
        table.insertColumn("at");
        break;
      case "color":
        setOpenSettings(dragType);
        break;
      default:
        return;
    }

    const omitValues = ["color", "delete"];

    if (!omitValues.includes(value)) {
      resetSelection();
    }
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    delete updateData.type;
    table.updateTableStyle(updateData, {
      ...tableProps,
    });
    onClose();
  };

  const commonDragBtnProps = {
    anchorEl: cellRef,
    handleToolAction,
    customProps,
    dndProps,
    resetSelection,
  };

  const showRowDragBtn =
    (showRowDrag || isRowDragging) && containerEle?.scrollLeft <= 0;

  return (
    <>
      <td
        {...element.attr}
        {...attributes}
        className={`editor-table-cell ${hasSelected ? "selection" : ""}`}
        style={{
          position: "relative",
          background: bgColor || entireBgColor,
          border: cellBorderColor
            ? `3px solid ${cellBorderColor}`
            : "1px solid #E0E0E0",
          fontFamily: entireFontFamily || "inherit",
          fontWeight: entireFontWeight || "inherit",
          fontSize: entireTextSize || "inherit",
          color: entireTextColor || "inherit",
          cursor: "text",
          ...(sizeProps || {}),
        }}
        {...tbProps}
        data-cell={path.toString()}
      >
        {isFirstRow && currentDraggingType === "col" ? (
          <Droppable
            {...dndProps}
            dragType={currentDraggingType}
            tableDOM={tableDOM}
          ></Droppable>
        ) : null}

        {isFirstColumn && currentDraggingType === "row" ? (
          <Droppable
            {...dndProps}
            dragType={currentDraggingType}
            tableDOM={tableDOM}
          ></Droppable>
        ) : null}

        {children}
        {isHeader && !readOnly && tableSize?.height && !showTool ? (
          <Resizer
            classes={classes}
            onMouseDown={onMouseDown}
            height={tableDOM.getBoundingClientRect()?.height}
          />
        ) : null}
        {hasSelected && !readOnly ? (
          <div className="selection-area-tc" contentEditable={false} />
        ) : null}

        {showColDrag || isColDragging ? (
          <DragButton
            {...commonDragBtnProps}
            placement="top"
            dragType="col"
            onDrag={onColDrag}
            hideDelete={rowProps?.children?.length <= 1}
          />
        ) : null}

        {showRowDragBtn ? (
          <DragButton
            {...commonDragBtnProps}
            dragType="row"
            onDrag={onRowDrag}
            hideDelete={parentProps?.children?.length <= 1}
            className={dragRowBtnCls}
          />
        ) : null}
      </td>

      {openSettings ? (
        <TablePopup
          element={tableProps?.styleProps || {}}
          customProps={customProps}
          onSave={onSave}
          onClose={onClose}
          styleType={openSettings}
        />
      ) : null}
    </>
  );
};

export default TableCell;
