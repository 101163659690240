const pageSettingsStyle = [
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text",
        key: "color",
        type: "color",
      },
      {
        label: "Background URL",
        key: "pageBgImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "pageBgImage",
        type: "backgroundImage",
      },
      {
        label: "Background",
        key: "pageColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Spacing",
    value: "spacing",
    fields: [
      {
        label: "Padding",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
      {
        label: "Line Spacing",
        key: "lineHeight",
        type: "lineSpacing",
      },
    ],
  },
  {
    tab: "Max Width",
    value: "maxWidth",
    fields: [
      {
        label: "Max Width",
        key: "maxWidth",
        type: "text",
        placeholder: "Maximum width of page in px",
      },
    ],
  },
];

export default pageSettingsStyle;
