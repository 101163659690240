import { sizeMap } from "../utils/font";

export const breakpoints = {
  small: 0,
  mobile: 600,
  tablet: 900,
};

export const BREAKPOINTS_DEVICES = ["xs", "sm", "md", "lg"];

export const getDevice = (width) => {
  // stimulated environment
  const breakpoint = document.getElementsByClassName("et-wrpr")[0];
  const etDataset = breakpoint?.dataset;
  if (etDataset?.breakpoint) {
    return etDataset?.breakpoint;
  }

  if (width > 0 && width < breakpoints.mobile) {
    return "xs";
  } else {
    return "lg";
  }
};

const copyAllLg = (value, ot) => {
  if (value && value["lg"] !== undefined) {
    return value;
  }

  return BREAKPOINTS_DEVICES.reduce((a, b) => {
    return {
      ...a,
      [b]: overrides[ot] ? overrides[ot](value) : value,
    };
  }, {});
};

const overrideValues = (value, ot) => {
  return Object.keys(value).reduce((a, b) => {
    return {
      ...a,
      [b]: overrides[ot] ? overrides[ot](value[b]) : value,
    };
  }, {});
};

const overrides = {
  overrideText: (val) => {
    return sizeMap[val] || val;
  },
  overrideGridSize: (val) => {
    return `${((val || 8) / 12) * 100}%`;
  },
  overrideReSize: (val) => {
    return `${val?.widthInPercent || 100}%`;
  },
  overrideReSizeH: (val) => {
    return val ? `${val?.height}px` : "auto";
  },
  overrideBorderRadius: (val) => {
    return `${val?.topLeft}px ${val?.topRight}px ${val?.bottomLeft}px ${val?.bottomRight}px`;
  },
};

export const getBreakPointsValue = (
  value,
  breakpoint,
  ot = null,
  ov = false
) => {
  try {
    if (breakpoint) {
      if (typeof value !== "object") {
        return value;
      }

      if (value && value["lg"] === undefined) {
        let val = copyAllLg(value, ot);
        return val ? val[breakpoint] || val["lg"] : val;
      } else {
        return value ? value[breakpoint] || value["lg"] : value;
      }
    } else if (typeof value === "object") {
      return !breakpoint && value["lg"]
        ? !ov
          ? value
          : overrideValues(value, ot)
        : value[breakpoint] || copyAllLg(value, ot);
    } else {
      // consider without breakpoints
      return copyAllLg(value, ot);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTRBLBreakPoints = (value, breakpoint) => {
  try {
    const values = getBreakPointsValue(value, breakpoint);
    const cssVal = BREAKPOINTS_DEVICES.reduce((a, b) => {
      if (values[b] || values["lg"]) {
        const { top, right, bottom, left } = values[b] || values["lg"];
        return {
          ...a,
          [b]: `${top || 0}px ${right || 0}px ${bottom || 0}px ${left || 0}px`,
        };
      } else {
        return a;
      }
    }, {});
    if (breakpoint) {
      return value[breakpoint] || value["lg"] || value;
    } else {
      return cssVal;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getBRValue = (props, rbr = "lg") => {
  const { value, breakpoint, ot, ov } = props;
  const val = getBreakPointsValue(value, breakpoint, ot, ov);
  return val[rbr] || val["lg"];
};

const isObject = (value) => {
  return Object.prototype.toString.call(value) === "[object Object]";
};

export const groupByBreakpoint = (styleProps, theme) => {
  const tProps = Object.keys(styleProps);
  const groupedXS = tProps.reduce((a, b) => {
    if (isObject(styleProps[b])) {
      a[b] = styleProps[b]["xs"];
      if (b === "height" && !a[b]) {
        a[b] = "auto";
      }
    } else {
      a[b] = styleProps[b];
    }
    return a;
  }, {});
  const groupedLG = tProps.reduce((a, b) => {
    if (isObject(styleProps[b])) {
      a[b] = styleProps[b]["lg"] || styleProps[b];
    } else {
      a[b] = styleProps[b];
    }
    return a;
  }, {});
  return {
    [theme?.breakpoints?.up("md")]: {
      ...groupedLG,
    },
    [theme?.breakpoints?.between("xs", "md")]: {
      ...groupedXS,
    },
  };
};

export const getCustomizationValue = (value) =>
  isNaN(parseInt(value)) ? null : parseInt(value);

export const getBreakpointLineSpacing = (value, breakpoint) => {
  try {
    const values = getBreakPointsValue(value, breakpoint);
    const cssVal = BREAKPOINTS_DEVICES.reduce((a, b) => {
      if (values[b] || values["lg"]) {
        const value = values[b] || values["lg"];
        return {
          ...a,
          [b]: value,
        };
      } else {
        return a;
      }
    }, {});
    if (breakpoint) {
      return value[breakpoint] || value["lg"] || value;
    } else {
      return cssVal["lg"];
    }
  } catch (err) {
    // console.log(err);
  }
}