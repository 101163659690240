import { useDraggable } from "@dnd-kit/core";
import { Box } from "@mui/material";

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable(props);

  const { dragType } = props?.data || {};

  const style = transform
    ? {
        transform:
          dragType === "row"
            ? `translate3d(0, ${transform.y}px, 0)`
            : `translate3d(${transform.x}px, 0, 0)`,
      }
    : undefined;

  return (
    <Box style={style} ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </Box>
  );
}

export default Draggable;
