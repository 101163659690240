import React from "react";

export function PaintIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.42594 16.4984L2.75011 12.8226C1.52177 11.5943 1.52177 10.3751 2.75011 9.14676L8.87344 3.02344L15.6109 9.76097C15.9501 10.1001 15.9501 10.6501 15.6109 10.9893L10.0926 16.5076C8.88261 17.7176 7.65427 17.7176 6.42594 16.4984Z"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65405 1.78906L8.88239 3.01736"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.89746 10.9253L15.7575 10.3203"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 20.168H14.6667"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2791 13.75C17.2791 13.75 15.5833 15.5925 15.5833 16.72C15.5833 17.655 16.3441 18.4158 17.2791 18.4158C18.2141 18.4158 18.9749 17.655 18.9749 16.72C18.9749 15.5925 17.2791 13.75 17.2791 13.75Z"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DuplicateIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6666 10.2112V13.5362C12.6666 16.307 11.5583 17.4154 8.78742 17.4154H5.46242C2.69159 17.4154 1.58325 16.307 1.58325 13.5362V10.2112C1.58325 7.44036 2.69159 6.33203 5.46242 6.33203H8.78742C11.5583 6.33203 12.6666 7.44036 12.6666 10.2112Z"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4166 5.4612V8.7862C17.4166 11.557 16.3083 12.6654 13.5374 12.6654H12.6666V10.2112C12.6666 7.44036 11.5583 6.33203 8.78742 6.33203H6.33325V5.4612C6.33325 2.69036 7.44158 1.58203 10.2124 1.58203H13.5374C16.3083 1.58203 17.4166 2.69036 17.4166 5.4612Z"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AboveArrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5642 8.77344L11 3.20927L5.43588 8.77344"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 18.793L11 3.36547"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BelowArrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5642 13.2266L11 18.7907L5.43588 13.2266"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 3.20703L11 18.6345"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RightArrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2275 16.5625L18.7917 10.9983L13.2275 5.43417"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.20825 11H18.6358"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LeftArrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.77246 16.5625L3.20829 10.9983L8.77246 5.43417"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7917 11L3.36425 11"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SettingsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 10.7339V9.26718C1.66675 8.40052 2.37508 7.68385 3.25008 7.68385C4.75841 7.68385 5.37508 6.61718 4.61675 5.30885C4.18341 4.55885 4.44175 3.58385 5.20008 3.15052L6.64175 2.32552C7.30008 1.93385 8.15008 2.16718 8.54175 2.82552L8.63341 2.98385C9.38341 4.29218 10.6167 4.29218 11.3751 2.98385L11.4667 2.82552C11.8584 2.16718 12.7084 1.93385 13.3667 2.32552L14.8084 3.15052C15.5667 3.58385 15.8251 4.55885 15.3917 5.30885C14.6334 6.61718 15.2501 7.68385 16.7584 7.68385C17.6251 7.68385 18.3417 8.39218 18.3417 9.26718V10.7339C18.3417 11.6005 17.6334 12.3172 16.7584 12.3172C15.2501 12.3172 14.6334 13.3838 15.3917 14.6922C15.8251 15.4505 15.5667 16.4172 14.8084 16.8505L13.3667 17.6755C12.7084 18.0672 11.8584 17.8339 11.4667 17.1755L11.3751 17.0172C10.6251 15.7089 9.39175 15.7089 8.63341 17.0172L8.54175 17.1755C8.15008 17.8339 7.30008 18.0672 6.64175 17.6755L5.20008 16.8505C4.44175 16.4172 4.18341 15.4422 4.61675 14.6922C5.37508 13.3838 4.75841 12.3172 3.25008 12.3172C2.37508 12.3172 1.66675 11.6005 1.66675 10.7339Z"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MoreIcon() {
  return (
    <svg
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99846 2.00034C9.99846 1.44825 9.55081 1.00069 8.99862 1.00069C8.44642 1.00069 7.99878 1.44825 7.99878 2.00034C7.99878 2.55244 8.44642 3 8.99862 3C9.55081 3 9.99846 2.55244 9.99846 2.00034Z"
        fill="#0F172A"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9972 2.00034C16.9972 1.44825 16.5496 1.00069 15.9974 1.00069C15.4452 1.00069 14.9976 1.44825 14.9976 2.00034C14.9976 2.55244 15.4452 3 15.9974 3C16.5496 3 16.9972 2.55244 16.9972 2.00034Z"
        fill="#0F172A"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99968 2.00034C2.99968 1.44825 2.55203 1.00069 1.99984 1.00069C1.44764 1.00069 1 1.44825 1 2.00034C1 2.55244 1.44764 3 1.99984 3C2.55203 3 2.99968 2.55244 2.99968 2.00034Z"
        fill="#0F172A"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
