const GridStyles = (theme, appTheme) => ({
  gridButton: {
    background: "#007FFF",
    color: "#fff",
    "&:hover": {
      background: "#0066CC",
      color: "#FFF",
    },
  },
  gridEqual: {
    "&.equal-cols": {
      display: "flex",
      "& .element-selector.grid-s": {
        display: "none !important",
      },
      "& .element-selector-ctrl": {
        display: "none !important",
      },
      "& .grid-c-wrpr": {
        gap: "16px",
        flexWrap: "nowrap",
        [theme?.breakpoints?.between("xs", "md")]: {
          flexWrap: "wrap",
        },
      },
      "&.cc-1": {
        "& .grid-item": {
          "&:last-child": {
            "& .col-width-resizer": {
              display: "block !important",
            },
          },
        },
      },
      "& .grid-item": {
        minWidth: "var(--minWidth)",
        maxWidth: "var(--minWidth)",
        "& .element-selector": {
          border: "1px solid transparent",
          "& .element-selector-dots": {
            display: "none !important",
          },
        },
        "& .col-width-resizer": {
          "&.last-resize": {
            display: "none",
          },
        },
        flex: "1 1 0",
        height: "100%",
        [theme?.breakpoints?.between("xs", "md")]: {
          flex: "1 1 100%",
          height: "auto",
          padding: "0px !important",
          minWidth: "100% !important",
          maxWidth: "100% !important",
          "& .col-width-resizer": {
            display: "none !important",
          },
        },
        "& .gi-inner-cw": {
          overflowX: "auto",
          wordBreak: "break-word",
          "& .tableToolBar": {
            display: "flex",
            maxWidth: "100%",
            overflowX: "auto",
          },
        },
      },
      "& .col-width-resizer": {
        position: "absolute",
        width: "4px",
        height: "100%",
        backgroundColor: "#CCC",
        left: "calc(100% + 4px)",
        top: "0px",
        cursor: "col-resize",
        opacity: 0,
        borderRadius: "7px",
        "&.resizing": {
          opacity: 1,
        },
        "&:hover": {
          opacity: 1,
        },
      },
    },
  },
  popTools: {
    zIndex: 1000,
    "& button": {
      borderRadius: "8px",
      marginRight: "4px",
      border: "1px solid rgba(37, 99, 235, 0.32)",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
      background: appTheme?.palette?.editor?.background,
      color: "#64748B",
      "& svg": {
        width: "16px",
        height: "16px",
        "& .fillStroke": {
          stroke: "#64748B",
        },
      },
      "&:hover": {
        background: appTheme?.palette?.editor?.background,
        border: "1px solid #2684ff",
        color: "#2684ff",
        "& .fillStroke": {
          stroke: "#2684ff",
        },
      },
    },
  },
});

export default GridStyles;
