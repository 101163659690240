import { Box, Fade, IconButton, Paper, Popover, Popper } from "@mui/material";
import DragStyles from "./DragStyles";
import { DragIcon } from "../../common/iconListV2";
import { useEditorContext } from "../../hooks/useMouseMove";
import { useRef, useState } from "react";
import { TableToolOnDrag } from "./TableTool";
import Draggable from "./Draggable";
import SwipeableDrawerComponent from "../../common/SwipeableDrawer";

function DragButton({
  anchorEl,
  placement,
  dragType,
  onDrag = () => {},
  handleToolAction,
  hideDelete,
  className = "",
  customProps,
  dndProps,
  resetSelection,
}) {
  const { theme } = useEditorContext();
  const classes = DragStyles(theme);
  const dragElement = useRef(null);
  const [showTool, setShowTool] = useState(false);
  const { isMobile } = customProps || {};

  const onDragClick = () => {
    setShowTool(true);

    onDrag();
  };

  const onDragClose = () => {
    setShowTool(false);
    setTimeout(() => resetSelection(), 200);
  };

  const dragProps = {
    ...dndProps,
    id: dndProps?.id + dragType,

    data: {
      ...dndProps.data,
      dragType,
    },
  };

  const handleAction = (value, option, dragType) => {
    const omitValues = ["delete"];

    if (!omitValues.includes(value)) {
      setShowTool(false);
    }

    handleToolAction(value, option, dragType);
  };

  const commonToolProps = {
    theme,
    dragType,
    hideDelete,
    handleToolAction: (value, option, dragType) => {
      handleAction(value, option, dragType);
    },
  };

  return (
    <>
      <Popper
        sx={classes.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={placement || "left"}
        transition
        contentEditable={false}
        ref={dragElement}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, -12],
            },
          },
          {
            name: "flip",
            enabled: false, // Disable dynamic flipping
          },
        ]}
        disablePortal={dragType !== "row"}
        className={`${className}`}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <Draggable {...dragProps}>
                <Paper
                  sx={dragType === "row" ? { transform: "rotate(90deg)" } : {}}
                  className={`${showTool ? "active" : ""}`}
                  style={{ lineHeight: 0 }}
                >
                  <IconButton
                    onClick={() => {
                      onDragClick();
                    }}
                  >
                    <DragIcon />
                  </IconButton>
                </Paper>
              </Draggable>
            </div>
          </Fade>
        )}
      </Popper>

      {isMobile ? (
        <SwipeableDrawerComponent
          open={showTool}
          onClose={onDragClose}
          swipeableDrawer={true}
        >
          <Box sx={classes.mobileToolDrawer}>
            <TableToolOnDrag {...commonToolProps} />
          </Box>
        </SwipeableDrawerComponent>
      ) : (
        <Popover
          open={showTool}
          anchorEl={dragElement?.current}
          transition
          contentEditable={false}
          sx={classes.toolPopper}
          anchorOrigin={{
            vertical: dragType === "row" ? "bottom" : "top",
            horizontal: dragType === "row" ? "left" : "right",
          }}
          onClose={onDragClose}
        >
          <TableToolOnDrag {...commonToolProps} />
        </Popover>
      )}
    </>
  );
}

export default DragButton;
