import React from "react";
import { Select, MenuItem } from "@mui/material";
import { addMarkData, activeMark } from "../../utils/SlateUtilityFunctions.js";
import { fontFamilyMap } from "../../utils/font";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const Dropdown = ({ classes, editor, format, options, width }) => {
  const value = activeMark(editor, format);

  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
    <Select
      value={value}
      className="editor-dd"
      onChange={(e) => changeMarkData(e, format)}
      MenuProps={{
        PaperProps: {
          sx: classes?.textOptions,
        },
      }}
      sx={classes.textFormatSelect}
      IconComponent={KeyboardArrowDownRoundedIcon}
    >
      {options.map((item, index) => (
        <MenuItem
          // style={{ fontFamily: item.text }}
          key={index}
          value={item.value}
          sx={classes.textFormatSelectOptions}
        >
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.defaultProps = {
  icon: null,
};

export default Dropdown;
