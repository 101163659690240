import { Box, Card, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import Icon from "../../Icon";
import { useEditorContext } from "../../../hooks/useMouseMove";


const RenderCard = ({ value, handleChange, title, content, infoIcon, classes }) => {
    return (
        <Card sx={{ position: 'relative', padding: "10px", '& .MuiCheckbox-root svg': { fill: 'unset !important' } }}>
            <FormControlLabel
                control={<Checkbox sx={{ padding: 0, marginTop: '3px' }} onChange={handleChange} checked={value} size="small" icon={<Icon icon="uncheckedIcon" />}
                    checkedIcon={<Icon icon="checkedIcon"/>} />}
                label={
                    <Box sx={{ display: "flex", alignItems: "center", "& .MuiTypography-root": { fontWeight: `500 !important` } }}>
                        <>
                            <Typography sx={classes?.cardsTypo} style={{ fontWeight: `500 !important` }}>{title}</Typography>
                            <Tooltip title={content}>
                                {infoIcon && content && (
                                    <span>
                                        {infoIcon}
                                    </span>
                                )}
                            </Tooltip>
                        </>
                    </Box>
                }
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    margin: 0,
                }}
                labelPlacement="start"
            />
        </Card>
    )
}


const CardsMapping = (props) => {
    const { value, data, onChange, classes } = props;
    const { key, label, content, selectedCard, infoIcon } = data;
    const { theme } = useEditorContext()

    const activeCard = value === selectedCard;

    const handleChange = (e) => {
        if (selectedCard === data?.value) {
            onChange({ [key]: "" });
        } else {
            onChange({ [key]: data?.value });
        }
    };
    return (
        <Grid item xs={12} sx={{ marginBottom: "12px", "& .MuiPaper-root": { background: theme?.palette?.editor?.miniToolBarBackground, border: activeCard ? "1px solid #2563EB" : `1px solid ${theme?.palette?.editor?.inputFieldBorder}`, borderRadius: "8px", boxShadow: activeCard ? "0px 4px 16px 0px #2563EB40" : "unset", } }}>
            <RenderCard value={activeCard} handleChange={handleChange} title={label} content={content} infoIcon={infoIcon} classes={classes} />
        </Grid>
    )
}

export default CardsMapping