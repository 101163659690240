import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Icon from "../../common/Icon";
import { ToggleFullScreenIcon } from "../../common/iconListV2";
import { SearchIcon } from "../../common/iconslist";

const SearchBox = (props) => {
  const { search, classes, handleChange } = props;
  return (
    <TextField
      sx={classes.searchBox}
      size="small"
      value={search}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

const PopperHeader = (props) => {
  const {
    title,
    classes,
    onClose,
    needFullscreen,
    fullScreen,
    toggleFullscreen,
    search,
    onSearch,
  } = props;

  const [openSearch, setOpenSearch] = useState(false)

  const handleSearchButton = () => {
    setOpenSearch(!openSearch)
  }

  return (
    <Grid
      container
      padding={2}
      className="headerContainer"
      style={{
        paddingTop: "5px",
      }}
    >
      <Grid
        item
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={classes.textSettingHeader}
      >
        <Typography
          color={"primary"}
          sx={{ fontSize: "16px", fontWeight: "700", color: "#000" }}
        >
          {title}
        </Typography>
        <Grid sx={classes.textFormatLabel} justifyContent={"end"}>
          {needFullscreen ? (
            <Grid sx={{position: 'relative'}} className="inputField">
              <IconButton className={openSearch ? 'searchContainer' : ''} onClick={handleSearchButton}><Icon icon={openSearch ? 'closeIcon' : 'SearchIcon'} /></IconButton>
              {openSearch &&
                <SearchBox
                  classes={classes}
                  search={search}
                  handleChange={onSearch}
                />}
            </Grid>
          ) : null}
          {needFullscreen ? (
            <Tooltip title="Toggle Fullscreen" arrow>
              <IconButton
                style={{ marginRight: "8px", marginLeft: "4px" }}
                className="close-popupbtn toogleFullScreenBtn"
                onClick={toggleFullscreen}
              >
                {fullScreen ? <CloseFullscreenIcon /> : <ToggleFullScreenIcon />}
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Close" arrow>
            <IconButton
              sx={classes.closeBtn}
              className="close-popupbtn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PopperHeader;
