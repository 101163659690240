import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";

function getStyle(dragType, isOver, tableDOM) {
  const style = {};

  const borderStyle = "4px solid #2563EB";

  if (isOver) {
    const { width, height } = tableDOM.getBoundingClientRect();
    if (dragType === "row") {
      style.borderTop = borderStyle;
      style.width = width;
    } else if (dragType === "col") {
      style.borderLeft = borderStyle;
      style.height = height;
    }
  }

  return style;
}

export function Droppable(props) {
  const { dragType, tableDOM, ...rest } = props;
  const { isOver, setNodeRef } = useDroppable(rest);

  return (
    <>
      <Box
        ref={setNodeRef}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />

      {isOver ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            ...getStyle(dragType, isOver, tableDOM),
          }}
        ></Box>
      ) : null}
    </>
  );
}
