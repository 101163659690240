import CustomSelect from "../AI/CustomSelect";
import Styles from "../AI/Styles";
import { CloseIcon, DeleteIcon } from "../../assets/svg/AIIcons";
import {
  AboveArrow,
  BelowArrow,
  DuplicateIcon,
  LeftArrow,
  PaintIcon,
  RightArrow,
} from "../../assets/svg/TableIcons";

const wholeTableTools = [
  {
    group: "",
    groupLabel: "",
    options: [
      {
        label: "Duplicate",
        value: "duplicate",
        Icon: DuplicateIcon,
      },
      {
        label: "Delete",
        value: "delete",
        Icon: DeleteIcon,
      },
    ],
  },
];

const insertColOptions = [
  {
    label: "Insert Right",
    value: "insertRight",
    Icon: RightArrow,
  },
  {
    label: "Insert Left",
    value: "insertLeft",
    Icon: LeftArrow,
  },
];

const insertRowOptions = [
  {
    label: "Insert Above",
    value: "insertAbove",
    Icon: AboveArrow,
    replace: false,
  },
  {
    label: "Insert Below",
    value: "insertBelow",
    Icon: BelowArrow,
    replace: false,
  },
];

function getTools(dragType, hideDelete) {
  const insertOptions =
    dragType === "col" ? insertColOptions : insertRowOptions;

  const deleteOption = hideDelete
    ? []
    : [
        {
          label: "Delete",
          value: "delete",
          Icon: DeleteIcon,
          replace: false,
        },
      ];

  let tools = [
    {
      group: "",
      groupLabel: "",
      options: [
        {
          label: "Color",
          value: "color",
          Icon: PaintIcon,
        },
        {
          label: "Duplicate",
          value: "duplicate",
          Icon: DuplicateIcon,
        },
        ...insertOptions,
        {
          label: "Clear Contents",
          value: "clear",
          Icon: CloseIcon,
        },
        ...deleteOption,
      ],
    },
  ];

  return tools;
}

export function TableToolOnDrag({
  theme,
  dragType,
  handleToolAction,
  hideDelete,
}) {
  const classes = Styles(theme);
  const tools = getTools(dragType, hideDelete);

  return (
    <CustomSelect
      classes={classes}
      show={true}
      options={tools}
      onSend={(value, option) => {
        handleToolAction(value, option, dragType);
      }}
      btnProps={{
        onMouseDown: (e) => e.stopPropagation(),
        onMouseUp: (e) => e.stopPropagation(),
      }}
    />
  );
}

function TableTool({ theme, handleToolAction }) {
  const classes = Styles(theme);

  return (
    <CustomSelect
      classes={classes}
      show={true}
      options={wholeTableTools}
      onSend={(value, option) => {
        handleToolAction(value, option);
      }}
    />
  );
}

export default TableTool;
