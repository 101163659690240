import React from "react";
import { Box } from "@mui/material";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../../helper/theme";

const FormEmail = (props) => {
  const { fieldProps } = props;
  const {
    bannerSpacing,
    height,
    borderRadius,
    borderStyle,
    borderWidth,
    borderColor,
    textColor,
    bgColor,
    lockSpacing,
    textSize,
    fontFamily,
    fontWeight,
    ...rest
  } = fieldProps;

  const onChange = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{ width: "100%", display: "flex" }} contentEditable={false}>
      <Box
        component={"input"}
        type="email"
        required
        {...rest}
        onChange={onChange}
        sx={{
          width: "100%",
          border: `1px solid ${borderColor || "#FFF"}`,
          padding: {
            ...getTRBLBreakPoints(bannerSpacing),
          },
          height: height || "auto",
          borderColor: borderColor || "transparent",
          borderWidth: borderWidth || "1px",
          borderRadius: {
            ...getBreakPointsValue(
              borderRadius || {},
              null,
              "overrideBorderRadius",
              true
            ),
          },
          borderStyle: borderStyle || "solid",
          color: textColor || "#000",
          background: bgColor || "transparent",
          fontFamily: fontFamily || "PoppinsRegular",
          fontSize: textSize || "inherit",
          fontWeight: `${fontWeight} !important` || "400 !important" ,
        }}
      />
    </div>
  );
};

export default FormEmail;
