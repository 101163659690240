import React, { useState } from "react";
import { Transforms, Node, Path } from "slate";
import { useSlateStatic } from "slate-react";
import { ReactEditor } from "slate-react";
import { Box, useTheme } from "@mui/material";
import RnD from "../../common/RnD";
import { onDropItem } from "../../common/RnD/Utils/gridDropItem";
import useBreakpoints from "../../hooks/useBreakpoints";
import {
  breakpointValues,
  formatBreakpointValues,
} from "./breakpointConstants";
import { useEditorContext } from "../../hooks/useMouseMove";
import focusOnNewItem from "../../helper/RnD/focusOnNewItem";
import { bringItemToFB } from "../../helper";
import itemOptions from "./Options/sectionItemOptions";
import { formField } from "../../utils/formfield";
import Workflow from "../Form/Workflow";
import EmbedScriptPopup from "../EmbedScript/EmbedScriptPopup";

const FreeGridItem = (props) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme);
  const { element, attributes, children, customProps } = props;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { readOnly, hideTools } = customProps;
  const { updated_at, childType, zIndex } = element;
  // get values based on breakpoint size
  const { gridArea, width, height, left, top, marginTop } = breakpointValues(
    element.type,
    breakpoint,
    element
  );
  const { setSelectedElement, selectedElement } = useEditorContext();
  const arrangeIndex = zIndex === undefined ? path[path.length - 1] : zIndex;
  const [popup, setPopup] = useState(null);

  const onChangeSettings = () => {};

  const onChange = (data) => {
    let updateData = { ...data };
    delete updateData.children;
    updateData = formatBreakpointValues(element.type, breakpoint, updateData);
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
  };

  const handleDragEvent = (type, data, cb) => {
    const toPosition = {
      left: left + data.lastX,
      top: top + data.lastY,
    };
    const { node, ...rest } = data;
    switch (type) {
      case "stop":
        const updated_data = onDropItem(
          {
            editor,
            fromPosition: { left, top },
            toPosition,
            path,
            ref: node,
            breakpoint,
            ...rest,
          },
          ".freegrid-container"
        );
        if (cb) {
          cb(updated_data);
        }
        break;
      default:
    }
  };

  const handleResizeEvent = (type, data, cb) => {
    switch (type) {
      case "stop":
        return;
      default:
    }
  };

  const onDelete = () => {
    try {
      Transforms.removeNodes(editor, { at: path });
    } catch (err) {
      console.log(err);
    }
  };

  const handleContextMenuClick = (d) => {
    switch (d?.name) {
      case "bringForward":
        bringItemToFB(editor, { path, moveFront: true });
        break;
      case "bringBackward":
        bringItemToFB(editor, { path, moveFront: false });
        break;
      case "cut":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        onDelete();
        focusOnNewItem(editor, Path.parent(path), {
          setSelectedElement,
        });
        break;
      case "copy":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        break;
      case "delete":
        onDelete();
        setSelectedElement({});
        break;
      default:
        return;
    }
  };

  const onAddFormField = () => {
    try {
      const {
        fieldFontFamily,
        fieldFontWeight,
        fieldTextSize,
        fieldTextColor,
        fieldBgColor,
        fieldBorderColor,
        fieldBorderWidth,
        fieldBorderStyle,
      } = element?.children[0];
      Transforms.insertNodes(
        editor,
        {
          ...formField({
            fontFamily: fieldFontFamily ? fieldFontFamily : "",
            fontWeight: fieldFontWeight ? fieldFontWeight : "",
            textSize: fieldTextSize ? fieldTextSize : "",
            textColor: fieldTextColor ? fieldTextColor : "",
            bgColor: fieldBgColor ? fieldBgColor : "",
            borderColor: fieldBorderColor ? fieldBorderColor : "",
            borderWidth: fieldBorderWidth ? fieldBorderWidth : "",
            borderStyle: fieldBorderStyle ? fieldBorderStyle : "",
          }),
        },
        { at: [...path, 0, 0] }
      );
    } catch (err) {
      console.log(err, "Add Field Error in Form Builder");
    }
  };

  const onClose = () => {
    setPopup(null);
    setSelectedElement({
      ...selectedElement,
      enable: 1,
    });
  };

  const selectCode = () => {
    try {
      const emsTarget = {
        anchor: {
          path: [...path, 0, 0],
          offset: 0,
        },
        focus: {
          path: [...path, 0, 0],
          offset: 0,
        },
      };
      Transforms.select(editor, emsTarget);
    } catch (err) {
      console.log(err);
    }
  };

  const onWorkflowSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...path, 0],
      }
    );
  };

  const handleActionClick = (type) => {
    switch (type) {
      case "workFlow":
        setSelectedElement({
          ...selectedElement,
          enable: 2,
        });
        setPopup("workflow");
        break;
      case "addFormField":
        onAddFormField();
        break;
      case "addEmbedScript":
        setSelectedElement({
          ...selectedElement,
          enable: 2,
        });
        selectCode();
        setPopup("embedScript");
        break;
      default:
        break;
    }
  };

  const itemTypeOptions = (
    itemOptions[childType] || itemOptions?.default
  ).filter((f) => (hideTools || []).indexOf(f) === -1);

  return (
    <RnD
      id={`freegrid_item_${path.join("|")}_${updated_at}_${breakpoint}`}
      className={`freegrid-item path-${path.length} breakpoint-${breakpoint}`}
      editor={editor}
      path={path}
      actions={itemTypeOptions}
      settingsProps={{
        settings: "freegridItem",
        onChange: onChangeSettings,
        customProps,
        onlyPopup: true, // for signature popup in DnD
      }}
      style={{
        "--left": `${left || 0}px`,
        "--marginTop": `${marginTop || 0}px`,
        "--gridArea": `${gridArea}`,
        "--width": `${width}px`,
        "--height": `${height}px`,
        "--zIndex": 100 + arrangeIndex,
      }}
      defaultStyle={{
        height: `${height}px`,
        width: `${width}px`,
      }}
      gridArea={gridArea}
      onChange={onChange}
      delta={{ width, height }}
      handleDragEvent={handleDragEvent}
      handleResizeEvent={handleResizeEvent}
      readOnly={readOnly}
      childType={childType || ""}
      handleContextMenuClick={handleContextMenuClick}
      handleActionClick={handleActionClick}
      itemData={{ left: left || 0 }}
    >
      <Box
        component={"div"}
        {...attributes}
        className={`fgi_type_${childType}`}
      >
        {children}
        {popup === "workflow" ? (
          <Workflow
            openWorkflow={popup === "workflow"}
            element={element}
            customWorkflowElement={element?.children?.[0] || null}
            closeWorkflow={onClose}
            onSave={onWorkflowSave}
          />
        ) : null}
        {popup === "embedScript" ? (
          <EmbedScriptPopup
            open={popup === "embedScript"}
            customProps={customProps}
            editor={editor}
            onClose={onClose}
            updatePath={[...path, 0]}
            parentPath={[...path]}
          />
        ) : null}
      </Box>
    </RnD>
  );
};

export default FreeGridItem;
