import React, { useMemo, useState } from "react";
import { Grid, Button, Popover } from "@mui/material";
import ColorPickerTool from "react-gcolor-picker";
import { colors } from "../Elements/Color Picker/defaultColors";

const ColorPickerButton = (props) => {
  const {
    value,
    onSave,
    defaultColors = [],
    classes = {},
    recentColors = [],
    hideGradient,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(value);
  const open = Boolean(anchorEl);

  const handleColorPicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSave = () => {
    onSave(color);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setColor(color);
  };

  const initialColors = useMemo(() => {
    let colors = [...recentColors, ...defaultColors];

    if (hideGradient) {
      colors = colors.filter((c) => c && !c.includes("gradient"));
    }

    return colors;
  }, [recentColors, defaultColors, hideGradient]);

  return (
    <>
      <Button
        style={{
          background: value,
          height: "22px",
          minWidth: "22px",
          borderRadius: "26px",
          // border: "2px solid #E7E7E7",
        }}
        onClick={handleColorPicker}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handleClose}
        sx={classes.colorPickerPopup}
      >
        <Grid container padding={0} className="ed-cp-wrpr">
          <Grid item xs={12}>
            <div>
              <ColorPickerTool
                gradient={hideGradient ? false : true}
                value={color}
                onChange={handleColorChange}
                defaultColors={initialColors}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "8px",
              }}
            >
              <Button onClick={handleClose} className="secondaryBtn">
                Cancel
              </Button>
              <Button onClick={handleSave} className="primaryBtn">
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

ColorPickerButton.defaultProps = {
  defaultColors: [...colors],
};

export default ColorPickerButton;
