import { Box, IconButton, TextareaAutosize, Typography } from "@mui/material";
import Styles from "./Styles";
import Icon from "../../common/Icon";
// import { TbRotateClockwise } from "react-icons/tb";
// import { FaRegCircleCheck } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import WaveLoading from "../../common/WaveLoading";
import CustomSelect from "./CustomSelect";
import {
  editContentOptions,
  newContentOptions,
  generatedContentOptions,
} from "./helper";
import useClickOutside from "../../hooks/useClickOutside";
import { useEditorContext } from "../../hooks/useMouseMove";
import { ChatMicIcon } from "../../assets/svg/AIIcons";

function getProps(openAI, generatedText) {
  const dropDownProps = {
    fromElements: {
      options: newContentOptions,
      show: generatedText?.length,
    },
    fromToolBar: {
      options: editContentOptions,
      show: true,
    },
    generatedContent: {
      options: generatedContentOptions,
      show: true,
    },
  };

  if (openAI === "fromToolBar" && generatedText?.length) {
    return dropDownProps.generatedContent;
  }

  return dropDownProps[openAI];
}

function AIInput(
  {
    onSend,
    generatedText,
    openAI,
    inputValue,
    onInputChange,
    onClickOutside,
    startRecording,
    loading,
    isMobile,
  },
  ref
) {
  const { theme } = useEditorContext();
  const classes = Styles(theme);
  const inputRef = useRef();
  const inputWrapperRef = useRef();
  const [contentHeight, setContentHeight] = useState("100px");

  const refs = useClickOutside({
    onClickOutside,
    omitIds: ["infinity-select-popover"],
    omitClass: ["MuiModal-backdrop"],
    refCount: 2,
  });

  useEffect(() => {
    let timeoutId;
    if (inputRef.current && openAI !== "fromToolBar") {
      timeoutId = setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openAI]);

  const isSendBtnDisabled = !inputValue || loading;

  const props = getProps(openAI, generatedText) || {};

  const handleSendBtnClick = () => {
    if (isSendBtnDisabled) {
      return;
    }

    onSend("", {});
  };

  useEffect(() => {
    const selectWrapper = refs?.length ? refs[1]?.current : null;

    const slateWrapper = document.getElementById(
      "slate-wrapper-scroll-container"
    );

    if (selectWrapper && slateWrapper) {
      const height =
        slateWrapper.offsetHeight - selectWrapper.offsetHeight - 170;

      setContentHeight(height + "px");
    }
  }, [refs, generatedText]);

  return (
    <>
      <Box component={"div"} sx={classes.aiContainer} ref={refs[0]}>
        {generatedText ? (
          <Typography
            sx={classes.generatedText}
            style={{ maxHeight: contentHeight }}
            dangerouslySetInnerHTML={{
              __html: generatedText,
            }}
          ></Typography>
        ) : null}

        <Box
          component="form"
          sx={classes.aiInputWrapper}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="icon-container icons-elements" ref={inputWrapperRef}>
            <Icon icon="infinityIcon" />
          </div>

          {loading ? (
            <div className="loading-container">
              <WaveLoading />
            </div>
          ) : (
            <TextareaAutosize
              className="ai-input"
              placeholder={"Ask AI to write anything..."}
              ref={inputRef}
              value={inputValue}
              onChange={onInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSendBtnClick();
                }
              }}
            />
          )}

          <Box
            component="div"
            style={classes.sendIconContainer}
            className="icons-elements"
          >
            <IconButton disabled={loading} onClick={() => startRecording()}>
              <ChatMicIcon />
            </IconButton>

            <IconButton
              sx={isSendBtnDisabled ? classes.sendBtnDisabled : classes.sendBtn}
              onClick={() => handleSendBtnClick()}
            >
              <IoSend color="#fff" size={14} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box component="div" ref={refs[1]} sx={classes.customSelectWrapper}>
        <CustomSelect {...props} onSend={onSend} classes={classes} />
      </Box>
    </>
  );
}

export default forwardRef(AIInput);
