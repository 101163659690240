import React, { useState } from "react";
import {
    Grid,
    Slider,
    Typography,
    Box,
} from "@mui/material";
import { getBreakPointsValue } from "../../../helper/theme";
import useWindowResize from "../../../hooks/useWindowResize";
import { useEditorContext } from "../../../hooks/useMouseMove";

const LineSpacing = (props) => {
    const { value: val, data, onChange } = props;
    const { theme } = useEditorContext();
    const { key } = data;

    const [size] = useWindowResize();
    const pro_value = getBreakPointsValue(val, size?.device);
    const [value, setValue] = useState(pro_value);
    
    let breakpointValue = getBreakPointsValue(val, null);
    breakpointValue = typeof breakpointValue['lg'] === 'object' ? breakpointValue['lg'] : breakpointValue
    useState(() => {
        setValue(pro_value);
    }, [pro_value]);

    const handleChange = (e) => {
        onChange({
            [key]: {
                ...breakpointValue,
                [size?.device]: e.target.value,
            },
        });
    };

    return (
        <Grid item xs={12}>
            <Typography
                variant="body1"
                color={"primary"}
                style={{ fontSize: "14px", fontWeight: 500 }}
            >
                {data?.label}
            </Typography>

            <Grid container wrap="nowrap" className="sld-wrpr">
                <Slider
                    className="spacingSlider"
                    defaultValue={value || 1.43}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    min={0.5}
                    max={3.0}
                    step={0.1}
                    name="lineHeight"
                    onChange={handleChange}
                />
                <Box
                    component="input"
                    sx={{ background: theme?.palette?.editor?.background, color: theme?.palette?.editor?.textColor,}}
                    name="lineHeight"
                    value={pro_value}
                    className="sliderInput"
                    onChange={handleChange}
                    type="number"
                    placeholder="0"
                    disabled={true}
                    defaultValue={pro_value || 1.43}
                />
            </Grid>
        </Grid>
    );
};

export default LineSpacing;
