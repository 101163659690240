import React from "react";
import { Box } from "@mui/material";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../../helper/theme";

const FormNumbers = (props) => {
    const { fieldProps } = props;
    const {
        bannerSpacing,
        height,
        borderRadius,
        borderStyle,
        borderWidth,
        borderColor,
        textColor,
        bgColor,
        lockSpacing,
        textSize,
        fontFamily,
        fontWeight,
        ...rest
    } = fieldProps;

    const onChange = (e) => {
        e.preventDefault();
    };

    return (
        <div style={{ width: "100%", display: "flex" }} contentEditable={false}>
            <Box
                component={"input"}
                {...rest}
                type="tel"
                onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^0-9+]/g, '');
                    if (value.startsWith('+')) {
                        value = value[0] + value.slice(1).replace(/\+/g, '');
                    }
                    if (value.startsWith('+')) {
                        if (value.length > 16) {
                            value = value.slice(0, 16);
                        }
                    } else {
                        if (value.length > 15) {
                            value = value.slice(0, 15);
                        }
                    }
                    e.target.value = value;
                    onChange(e);
                }}
                sx={{
                    width: "100%",
                    border: `1px solid ${borderColor || "#FFF"}`,
                    padding: {
                        ...getTRBLBreakPoints(bannerSpacing),
                    },
                    height: height || "auto",
                    borderColor: borderColor || "transparent",
                    borderWidth: borderWidth || "1px",
                    borderRadius: {
                        ...getBreakPointsValue(
                            borderRadius || {},
                            null,
                            "overrideBorderRadius",
                            true
                        ),
                    },
                    borderStyle: borderStyle || "solid",
                    color: textColor || "#000",
                    background: bgColor || "transparent",
                    fontFamily: fontFamily || "PoppinsRegular",
                    fontSize: textSize || "inherit",
                    fontWeight: `${fontWeight} !important` || "400 !important" ,
                }}
            />
        </div>
    );
};

export default FormNumbers;
