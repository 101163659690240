const DragStyles = (theme) => {
  const { containers, editor } = theme?.palette || {};
  const {
    buttonBorder,
    background,
    activeColor,
    signaturePopUpTabButtonSelectedBg,
    signaturePopUpTabButtonSelectedSvg,
  } = editor || {};

  const onDragActive = {
    outline: `1.5px solid ${activeColor || "#2563EB"}`,
    background: `${signaturePopUpTabButtonSelectedBg} !important`,

    "& svg ellipse": {
      fill: `${signaturePopUpTabButtonSelectedSvg}`,
    },
  };

  return {
    popper: {
      zIndex: 1000,

      "& .MuiPaper-root": {
        borderRadius: "3px !important",
        outline: `1.5px solid ${buttonBorder}`,
        backgroundColor: `${background}`,

        "&:hover": onDragActive,

        "&.active": onDragActive,
      },

      "& .MuiIconButton-root": {
        padding: "4px 6px",
        borderRadius: "none",

        "& .dragIcon": {
          width: "14px",
          height: "10px",
        },

        "&:hover": {
          background: "none",
        },
      },
    },

    toolPopper: {
      zIndex: 4001,

      "& .MuiPaper-root": {
        borderRadius: "8px",
      },
    },
    mobileToolDrawer: {
      "& .customSelectContainer": {
        border: "none !important",
        padding: "0px !important",
      },
    },
    drawerContainer: {
      "&.MuiDrawer-root": {
        zIndex: 1301,
      },
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: containers?.card,
      },
      "& .customSelectContainer": {
        border: "none",
      },
    },
  };
};

export default DragStyles;
